
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const formData = ref({
      name: "",
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);

    const page = ref<string>("");

    // const value = ref('');
    const registration = Yup.object().shape({
      name: Yup.string().required().label("Name"),
    });

    onMounted(() => {
      const userData = localStorage.getItem("userdata");
      if (userData) {
        var allData = JSON.parse("" + userData + "");
        if (allData.type == "admin") {
          const data = route.params.id;
          if (data) {
            getPlan(data);
            // page.value = "Edit";
            setCurrentPageBreadcrumbs("Edit Plan", ["Plan"]);
          } else {
            // page.value = "Add";
            setCurrentPageBreadcrumbs("Add Plan", ["Plan"]);
          }

          store.dispatch(Actions.GET_AUTH_USER).then((data) => {
            //formData.value = data
            profile.value = data;
          });
          nextTick(() => {
            PasswordMeterComponent.bootstrap();
          });
          const id = route.params.id;
          if (id) {
            // getUser(id);
            page.value = "Edit";
          } else {
            page.value = "Add";
          }
        } else {
          router.push({ name: "dashbord" });
        }
      }
    });
    const getPlan = (id) => {
      var request = {
        url: `oneplan/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log(data)
          formData.value = {
            name: data.data.name,
          };
        }
      });
    };
    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      console.log("values", values);

      // var request = {
      //   url: 'profile/changePassword',
      //   data: {
      //     old_password: values.current_password,
      //     new_password: values.password,
      //     user_id: profile.value._id
      //   }
      // }
      // Dummy delay
      // setTimeout(() => {
      if (values.name.trim() == "") {
        Swal.fire({
          text: "Please enter valid name!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        loading.value = true;
        var id = route.params.id;
        var request = {
          url: `plan2/${id}`,
          data: {
            name: values.name,
          },
        };
        store
          .dispatch(Actions.PUT, request)
          .then((data) => {
            if (data) {
              Swal.fire("Success", "Plan Updated successfully!", "success");
              router.push({ name: "admin-plan" });
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
      // Send login request
      // store
      //   .dispatch(Actions.POST, request)
      //   .then((data) => {
      //     if(data){
      //       resetForm();
      //       Swal.fire({
      //         text: "You have successfully Changed your Password!",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-light-primary",
      //         },
      //       }).then(function () {
      //         router.push({ name: "dashboard" });

      //       });
      //     }
      //   })
      //   .catch(() => {
      //     const [error] = Object.keys(store.getters.getErrors);
      //     Swal.fire({
      //       text: store.getters.getErrors[error],
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Try again!",
      //       customClass: {
      //         confirmButton: "btn fw-bold btn-light-danger",
      //       },
      //     });
      //   });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      // }, 2000);
      // Swal.fire({
      //   text: "Plan added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   router.push({ name: "admin-plan" });
      // });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      page,
      getPlan,
      formData,
    };
  },
});
